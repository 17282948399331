.selectCurrency {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 1em;
  padding-right: 2em;
}

div.selectCurrency > div.select {
  font-size: var(--font-size-M);
  height: 3em;
  width: 200px;
}

div.selectCurrency > div.select > :global(div.ant-select-selector) {
  border-radius: 0px;
  color: var(--color-primary);
  font-size: var(--font-size-M);
  font-family: var(--font-sans-serif);
}
