.hero {
  position: relative;
  margin: 0;
  width: 100vw;
  height: max(100vh, 600px);
  color: #fffe;
  background-color: var(--color-primary);

  --color-bg: var(--color-primary);
  --gradient-angle: 150deg;
}

.hero .background {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.hero .background > * {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hero .background > video {
  transition: opacity 1.2s;
}

.hero .background > img {
  transition: opacity 0.3s;
}

.hero .background > .show {
  opacity: 1;
}

.hero .background > .hide {
  opacity: 0;
}

.hero .text {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: linear-gradient(
    var(--gradient-angle),
    var(--color-bg) 29.85%,
    rgba(240, 240, 240, 0) 61.87%
  );
  z-index: 1;
  font-size: 4vmin;
  line-height: 1;
  padding: 0 7vw;
  text-wrap: balance;
  max-width: auto;
}

.hero .text h1 {
  margin: 0;
  margin-bottom: 0.25em;
  font-size: 10vmin;
  padding: 0;
}

.hero .text h1::after {
  display: none;
}

.hero .text div.content {
  line-height: 1.2;
  max-width: auto;
}

.hero > footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 9;
}

.comingSoon {
  z-index: 8;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
}
