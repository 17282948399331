.teamMember {
  display: grid;
  grid-template-rows: 1fr auto auto;
  gap: 0;
  width: min(100vw, 300px);
  height: 240px;
}

.teamMember.big {
  width: min(100vw, 320px);
  height: 360px;
}

.teamMember > div.image {
  position: relative;
  content: '';
  background-size: cover;
  background-color: var(--color-primary);
}

.teamMember > div.image > img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  transition: opacity 1s;
}

.teamMember > div.image > img.ready {
  opacity: 1;
}

.teamMember > div.name {
  font-family: var(--font-serif);
  font-size: var(--font-size-M);
  background-color: var(--color-neutral-light);
  padding: 0 1em;
  margin: 0;
  line-height: 1;
  display: grid;
  place-items: center;
  height: 3em;
}

.teamMember.big > div.name {
  font-size: var(--font-size-L);
}

.teamMember > div.profile {
  text-align: right;
  font-size: var(--font-size-M);
}
