a.emailButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  border: 1px solid var(--color-neutral);
  padding: 48px;
  cursor: pointer;
  width: min(500px, calc(100vw - 8px));
  max-width: calc(100vw - 8px);
}

.emailButton > big {
  white-space: nowrap;
  font-size: var(--font-size-L);
  font-family: var(--font-serif);
  margin: 0;
}

@media (max-width: 640px) {
  a.emailButton {
    padding: 16px 8px;
  }
}
