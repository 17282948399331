.button {
  display: flex;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.button.end {
  justify-content: flex-end;
}

.button.center {
  justify-content: flex-center;
}

.button.start {
  justify-content: flex-start;
}

div.core {
  margin: 0;
  padding: 16px 20px;
  border: 1px solid var(--color-neutral);
  width: 365px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  max-width: calc(100vw - 3rem);
}

div.core > div:first-child {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.25rem;
  margin: 0;
  padding: 0;
}

div.core > div > * {
  margin: 0;
  padding: 0;
}

div.core > div:last-child {
  line-height: 125%;
  font-size: 2.25rem;
  margin: 0;
  padding: 0;
  leading-trim: both;
  text-edge: cap;
}

@media (max-width: 640px) {
  div.core {
    width: 320px;
    padding: 12px 16px;
  }
  div.core > div:first-child {
    font-size: 1.15rem;
  }
  div.core > div:last-child {
    font-size: 2rem;
  }
}

@media (max-width: 480px) {
  div.core {
    width: 240px;
    padding: 8px 12px;
  }
  div.core > div:first-child {
    font-size: 1rem;
  }
  div.core > div:last-child {
    font-size: 1.5rem;
  }
}
