button.newsCard {
  all: unset;
  position: relative;
  width: min(640px, calc(100vw - 8px));
  height: 240px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  gap: 0;
  flex: 0 0 auto;
  scroll-snap-stop: always;
  scroll-snap-align: start;
  cursor: pointer;
}

button.newsCard > div.content {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 8px;
  padding-right: 16px;
  z-index: 1;
  transition: all 0.6s;
}

button.newsCard:hover > div.content {
  background-color: var(--color-primary);
  color: #ffe;
}

button.newsCard > div.content > h2 {
  line-height: 1.2;
}

button.newsCard > div.content > * {
  flex: 0 0 auto;
}

button.newsCard > div.content > small {
  flex: 1 1 auto;
  color: #0008;
  font-weight: normal;
}

button.newsCard:hover > div.content > small {
  color: #fff8;
}

button.newsCard > div.content > p {
  margin: 0;
  font-size: var(--font-size-M);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

button.newsCard > div.picture {
  z-index: 0;
  width: 240px;
  flex: 0 0 auto;
  position: relative;
}

button.newsCard > div.picture > * {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
}

@media (max-width: 600px) {
  button.newsCard > div.picture {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  button.newsCard > div.content {
    padding: 1em;
    background-color: color-mix(in srgb, var(--color-primary) 66%, transparent);
    color: #fffe;
  }

  button.newsCard > div.content > small {
    color: #fffc;
  }

  button.newsCard:hover > div.content > small {
    color: #fffc;
  }
}
