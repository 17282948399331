button.hoverableButton,
a.hoverableButton,
.hoverableButton {
  background: #fff;
  color: var(--color-primary);
  transition: all 0.5s;
}

button.hoverableButton:hover,
a.hoverableButton:hover,
.hoverableButton:hover,
button.hoverableButton:active,
a.hoverableButton:active,
.hoverableButton:active {
  color: #fff;
  background: var(--color-primary);
}

button.hoverableButton[disabled],
a.hoverableButton[disabled] {
  pointer-events: none;
  opacity: 0.5;
  filter: grayscale(100%);
}

button.squareButton,
a.squareButton {
  display: inline-block;
  color: var(--color-primary);
  border: 1px solid currentColor;
  line-height: 3;
  padding: 0 2em;
  white-space: nowrap;
}

button.roundedButton,
a.roundedButton {
  display: inline-block;
  color: var(--color-primary);
  border: 1px solid currentColor;
  line-height: 3;
  padding: 0 2em;
  white-space: nowrap;
  border-radius: 99vmax;
}

@media (max-width: 480px) {
  button.squareButton,
  a.squareButton {
    padding: 0 1em;
  }
}

/*
1         2   3      4      5   6      7      8   9         10
|-padding-|---|-colL-|-colM-|-S-|-colM-|-colL-|---|-padding-|
|--------padM--------|--------M--------|--------padM--------|
*/
.layout {
  --padding: 8px;
  --width-max: calc(100vw - (2 * var(--padding)));
  --S: min(800px, var(--width-max));
  --M: min(1200px, var(--width-max));
  --padM: calc(0.5 * (100vw - var(--M)));
  --L: min(1512px, var(--width-max));
  --colM: calc(0.5 * (var(--M) - var(--S)));
  --colL: calc(0.5 * (var(--L) - var(--M)));

  grid-column: 1/-1;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns:
    var(--padding) 1fr var(--colL) var(--colM) var(--S) var(--colM) var(--colL)
    1fr var(--padding);
  place-items: center stretch;
}

.layout .blockFullWidth {
  grid-column: 1/-1;
}

.layout .blockFullWidthPadded {
  grid-column: 2/-2;
}

.layout .blockSmall {
  grid-column: 5/6;
  max-width: var(--S);
}

.layout .blockSmallExpandRight {
  grid-column: 5/-1;
  max-width: none;
}

.layout .blockMedium {
  grid-column: 4/7;
  max-width: var(--M);
}

.layout .blockLarge {
  grid-column: 3/8;
  max-width: var(--L);
}

.layout .blockSmallMarginLeft {
  grid-column: 1/5;
}
