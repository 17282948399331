.footerPanel {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  gap: 0;
  flex-wrap: nowrap;
  padding: 32px;
}

.footerPanel > * {
  flex: 1 1 auto;
  min-height: 240px;
}

.title {
  order: 2;
  padding: 1rem;
  background-color: var(--color-neutral-light);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.copyright {
  font-size: var(--font-size-S);
}

.title > h2 {
  text-align: right;
  line-height: 0.8;
}

.title > div:first-child {
  font-family: var(--font-serif);
  font-size: 1rem;
  line-height: 0.8;
  text-align: right;
}

.title > div:last-child {
  color: #0007;
}

.links {
  order: 3;
  display: grid;
  grid-template-rows: repeat(6, 1fr);
  padding: 0 12px;
  gap: 6px;
  font-size: 18px;
  min-width: 240px;
}

.links a {
  border: none;
  display: inline-flex;
  cursor: pointer;
  padding: 0.5em 0.5em;
  font-size: 1rem;
}

.links > div.section {
  font-size: var(--font-size-S);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 2em;
  padding: 0;
  margin: 0;
  border-bottom: 1px solid var(--color-neutral);
  box-shadow: 0 0 inset var(--color-primary);
  transition: 0.5s box-shadow;
}

.links > div.section > button {
  font-size: var(--font-size-S);
}

.links > div.section:hover {
  box-shadow: 0 -4px inset var(--color-primary);
  border-bottom: 1px solid var(--color-primary);
}

.links > div.section:first-child {
  border-top: none;
}

.links > div.section > a {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
}

.links > div.section > button {
  cursor: pointer;
  border-radius: 999vmax;
  padding: 0 0.3em;
}

.links > * {
  flex: 1 1 auto;
}

.links div.socialmedia a > span {
  display: none;
}

.subscribe {
  order: 4;
}

@media (max-width: 1000px) {
  .footerPanel {
    flex-wrap: wrap;
    padding: 16px;
  }

  .title,
  .links {
    width: 50%;
  }

  .subscribe {
    order: 1;
    margin: 16px 0;
    min-width: 100%;
  }
}

@media (max-width: 480px) {
  .footerPanel > .subscribe {
    min-height: auto;
    height: auto;
    gap: var(--gap-M);
    margin: 8px;
    min-width: calc(100% - 16px);
  }

  .footerPanel .links {
    width: 100%;
  }

  .footerPanel .title {
    order: 4;
    max-height: 6em;
    min-height: 6em;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .footerPanel .title > h2 {
    max-width: 6em;
  }

  .footerPanel {
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    padding: 0;
  }
}
