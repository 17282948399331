.column {
  flex: 0 0 auto;
  padding: 8px;
  --width: min(calc(100vw - 16px), 370px);
  width: var(--width);
  min-width: var(--width);
  scroll-snap-stop: always;
}

.column > h2 {
  display: block;
  text-transform: uppercase;
  font-family: var(--font-sans-serif);
  font-size: var(--font-size-L);
  font-size: clamp(20px, 2vw, 22px);
  font-weight: 400;
  letter-spacing: 0.02em;
  color: var(--color-neutral);
  width: 100%;
  background-color: #fff;
  /* position: sticky;
  top: var(--custom-menu-height, 0px); */
  left: auto;
  right: auto;
  bottom: auto;
}

.card {
  height: 240px;
  margin: 16px 0;
  padding: 8px;
  border: 1px solid var(--color-neutral);
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: 1fr 1fr;
  overflow: hidden;
}

.card > h3 {
  font-size: var(--font-size-L);
}

.image {
  width: 120px;
  height: 120px;
  min-width: 120px;
  min-height: 120px;
  border-radius: 50%;
  overflow: hidden;
}

.description {
  grid-column: 1/-1;
  font-size: var(--font-size-S);
  place-self: start;
}

@media (max-width: 480px) {
  .column {
    --width: calc(100vw - 16px);
  }
  .image {
    display: none;
  }
  .card {
    height: 160px;
  }
  .description {
    font-size: var(--font-size-M);
  }
  .card > h3 {
    font-size: var(--font-size-XL);
  }
}
