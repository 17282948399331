div.popupMenu {
  cursor: pointer;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  z-index: 9999;
  pointer-events: none;
  opacity: 1;
  background-color: #fff;
  transition: all 0.2s;
  transform: translateX(100%);
}

div.popupMenu > menu {
  position: absolute;
  left: 1rem;
  top: 1rem;
  right: 1rem;
  bottom: 1rem;
  width: auto;
  height: auto;
  font-size: 7vmin;
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 0;
  background-color: #fff;
  padding: 1rem;
  border: 1px solid var(--color-neutral);
  width: calc(100% - 2rem);
  height: calc(100% - 2rem);
  max-height: calc(100% - 2rem);
}

div.popupMenu > menu > header {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
}

div.popupMenu > menu > header > button.close {
  font-size: 1em;
}

div.popupMenu > menu > section {
  margin: 2rem 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0;
  position: absolute;
  left: 0;
  top: 3em;
  bottom: 0;
  width: 100%;
  height: auto;
}

div.popupMenu.show {
  opacity: 1;
  pointer-events: all;
  transform: translateX(0);
}

div.popupMenu.show section > .link {
  cursor: pointer;
  padding: 0.2em 0.5em;
  margin: 0;
  margin-top: -1px;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
  font-family: var(--font-sans-serif);
  font-size: 1em;
}

@media (min-width: 951px) {
  div.popupMenu.show {
    opacity: 0;
    pointer-events: none;
  }
}
