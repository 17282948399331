.repositoryCard {
  padding: 20px;
  border: 1px solid var(--color-neutral);
}

.repositoryCard > h2 {
  font-size: var(--font-size-XL);
}

.repositoryCard > header {
  font-size: var(--font-size-S);
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1em;
}

.repositoryCard p {
  line-height: 1.55;
}

.authorLabel {
  color: var(--color-neutral);
}

.authorName {
  border-radius: 99vmax;
  border: 1px solid var(--color-neutral);
  padding: 0 1em;
  line-height: 2;
}

.image {
  width: 100%;
  aspect-ratio: 3/1;
  margin: 1em 0;
}

a.button {
  display: inline-block;
  width: auto;
  height: 3em;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
  padding: 0 1em;
  border: 1px solid var(--color-neutral);
  margin-top: 1em;
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1em;
}
