@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Text:ital@0;1&family=Gabarito:wght@400..900&display=swap');

.LandingPage_landingPage__TkmBR {
  --color-background: #fff;
  --color-on-bakground: #000;
  --color-neutral: var(--Colors-Gray-4, #bfbfbf);
  --color-neutral-light: var(--Colors-Gray-4, #f0f0f0);
  --color-on-neutral: var(--color-on-bakground);
  --color-primary: var(--Colors-Blue-8, #003a8c);
  --color-primary-light: var(--Colors-Blue-8, #1897e6);
  --color-on-primary: var(--color-background);
  --font-serif: 'DM Serif Text', serif;
  --font-sans-serif: 'Gabarito', sans-serif;
  --font-size-M: 19px;
  --font-size-S: calc(0.8 * var(--font-size-M));
  --font-size-M-plus: calc(1.2 * var(--font-size-M));
  --font-size-L: calc(1.5 * var(--font-size-M));
  --font-size-XL: calc(2 * var(--font-size-M));
  --gap-XL: 64px;
  --gap-L: 32px;
  --gap-M: 16px;
  --gap-S: 8px;
  --gap-XS: 4px;

  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  width: 100%;
  max-width: 100vw;
  margin: 0;
  padding: 0;
  color: var(--color-primary);
  background-color: var(--color-background);
  font-family: 'Gabarito', serif;
  font-optical-sizing: auto;
  font-weight: normal;
  font-style: normal;
  text-wrap: auto;
  font-size: var(--font-size-M);
  scroll-behavior: smooth;
}

.LandingPage_landingPage__TkmBR a,
.LandingPage_landingPage__TkmBR button {
  all: unset;
  cursor: pointer;
  font-size: var(--font-size-M);
  box-sizing: border-box;
}

.LandingPage_landingPage__TkmBR .font-gabarito {
  font-family: var(--font-sans-serif);
  font-optical-sizing: auto;
  font-weight: normal;
  font-style: normal;
}

.LandingPage_landingPage__TkmBR .font-dm {
  font-family: var(--font-serif);
  font-weight: 400;
  font-style: normal;
}

.LandingPage_landingPage__TkmBR .font-dm-italic {
  font-family: var(--font-serif);
  font-weight: 400;
  font-style: italic;
}

.LandingPage_landingPage__TkmBR h1 {
  font-size: var(--font-size-XL);
  margin: 1em 0;
  box-sizing: content-box;
  line-height: 1;
}

.LandingPage_landingPage__TkmBR h1::after {
  content: '';
  display: none;
  position: absolute;
  left: 0;
  top: 50%;
  width: calc(var(--margin) - 0.5em);
  height: 2px;
  background-color: var(--color-background, #000);
}

.LandingPage_landingPage__TkmBR h1 > big {
  display: block;
  font-weight: bolder;
  margin: 0;
}

.LandingPage_landingPage__TkmBR h2 {
  font-size: var(--font-size-L);
}

.LandingPage_landingPage__TkmBR h3 {
  font-size: var(--font-size-M);
}

.LandingPage_landingPage__TkmBR h1,
.LandingPage_landingPage__TkmBR h2,
.LandingPage_landingPage__TkmBR h3 {
  text-wrap: balance;
  position: relative;
  font-family: var(--font-serif);
}

.LandingPage_landingPage__TkmBR p {
  font-size: var(--font-size-M);
  text-wrap: auto;
  -webkit-hyphens: none;
          hyphens: none;
  /* text-align: justify;
  text-justify: distribute; */
  word-spacing: 0;
  line-height: 1.2;
}

@media (max-width: 640px) {
  .LandingPage_landingPage__TkmBR {
    --font-size-M: 16px;
  }
  .LandingPage_landingPage__TkmBR h1:after {
    display: none;
  }
}

@media (max-width: 480px) {
  .LandingPage_landingPage__TkmBR {
    --font-size-M: 14px;
  }
}

.LandingPage_landingPage__TkmBR big {
  font-weight: bold;
  font-size: 110%;
}

.ProgressiveImage_progressiveImage__VAHI6 {
  position: relative;
  background: var(--custom-background, transparent);
  aspect-ratio: var(--custom-aspect-ratio);
}

.ProgressiveImage_progressiveImage__VAHI6 > .ProgressiveImage_image__U3mDk {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  transition: opacity 0.3s;
  opacity: 0;
}

.ProgressiveImage_progressiveImage__VAHI6 > .ProgressiveImage_image__U3mDk.ProgressiveImage_show__3Kl0B {
  opacity: 1;
}

.social-media-links_socialMediaLinks__UJF4f {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1em;
}

.social-media-links_image__hxNNl {
  height: 1.5em;
}

.social-media-links_socialMediaLinks__UJF4f > a {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5em;
  cursor: pointer;
}

.social-media-links_title__Y9Whv {
  display: none;
}

button.styles_hoverableButton__EpLLS,
a.styles_hoverableButton__EpLLS,
.styles_hoverableButton__EpLLS {
  background: #fff;
  color: var(--color-primary);
  transition: all 0.5s;
}

button.styles_hoverableButton__EpLLS:hover,
a.styles_hoverableButton__EpLLS:hover,
.styles_hoverableButton__EpLLS:hover,
button.styles_hoverableButton__EpLLS:active,
a.styles_hoverableButton__EpLLS:active,
.styles_hoverableButton__EpLLS:active {
  color: #fff;
  background: var(--color-primary);
}

button.styles_hoverableButton__EpLLS[disabled],
a.styles_hoverableButton__EpLLS[disabled] {
  pointer-events: none;
  opacity: 0.5;
  filter: grayscale(100%);
}

button.styles_squareButton__BBwaa,
a.styles_squareButton__BBwaa {
  display: inline-block;
  color: var(--color-primary);
  border: 1px solid currentColor;
  line-height: 3;
  padding: 0 2em;
  white-space: nowrap;
}

button.styles_roundedButton__ANOaQ,
a.styles_roundedButton__ANOaQ {
  display: inline-block;
  color: var(--color-primary);
  border: 1px solid currentColor;
  line-height: 3;
  padding: 0 2em;
  white-space: nowrap;
  border-radius: 99vmax;
}

@media (max-width: 480px) {
  button.styles_squareButton__BBwaa,
  a.styles_squareButton__BBwaa {
    padding: 0 1em;
  }
}

/*
1         2   3      4      5   6      7      8   9         10
|-padding-|---|-colL-|-colM-|-S-|-colM-|-colL-|---|-padding-|
|--------padM--------|--------M--------|--------padM--------|
*/
.styles_layout___TkFo {
  --padding: 8px;
  --width-max: calc(100vw - (2 * var(--padding)));
  --S: min(800px, var(--width-max));
  --M: min(1200px, var(--width-max));
  --padM: calc(0.5 * (100vw - var(--M)));
  --L: min(1512px, var(--width-max));
  --colM: calc(0.5 * (var(--M) - var(--S)));
  --colL: calc(0.5 * (var(--L) - var(--M)));

  grid-column: 1/-1;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns:
    var(--padding) 1fr var(--colL) var(--colM) var(--S) var(--colM) var(--colL)
    1fr var(--padding);
  place-items: center stretch;
}

.styles_layout___TkFo .styles_blockFullWidth__p_ADf {
  grid-column: 1/-1;
}

.styles_layout___TkFo .styles_blockFullWidthPadded__hZgo_ {
  grid-column: 2/-2;
}

.styles_layout___TkFo .styles_blockSmall__HQUNn {
  grid-column: 5/6;
  max-width: var(--S);
}

.styles_layout___TkFo .styles_blockSmallExpandRight__D6bWw {
  grid-column: 5/-1;
  max-width: none;
}

.styles_layout___TkFo .styles_blockMedium__dGd_T {
  grid-column: 4/7;
  max-width: var(--M);
}

.styles_layout___TkFo .styles_blockLarge__sQ0uI {
  grid-column: 3/8;
  max-width: var(--L);
}

.styles_layout___TkFo .styles_blockSmallMarginLeft__MKq0M {
  grid-column: 1/5;
}

.NewsLetterSubscription_newsLetterSubscription__b0h3c {
  border: 1px solid var(--color-neutral);
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.NewsLetterSubscription_newsLetterSubscription__b0h3c > button {
  display: inline-block;
  padding: 0.5em 2em;
  cursor: pointer;
  border: 1px solid var(--color-neutral);
  border-radius: 99vmax;
  margin-top: 3rem;
}

.NewsLetterSubscription_newsLetterSubscription__b0h3c > input {
  width: min(90%, 400px);
  border: none;
  border-bottom: 1px solid var(--color-primary);
}

.NewsLetterSubscription_newsLetterSubscription__b0h3c > h2 {
  line-height: 0.8;
}

.NewsLetterSubscription_accepted__rDXDG {
  max-width: max(300px, 32%);
}

.NewsLetterSubscription_accepted__rDXDG > svg {
  width: 48px;
  height: 48px;
  margin: 0;
}

.NewsLetterSubscription_newsLetterSubscription__b0h3c.NewsLetterSubscription_accepted__rDXDG {
  justify-content: space-around;
  align-items: center;
  gap: 0;
}

.NewsLetterSubscription_newsLetterSubscription__b0h3c.NewsLetterSubscription_accepted__rDXDG > h2 {
  text-align: center;
  line-height: 2;
}

.NewsLetterSubscription_checkbox__FZ2om {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1em;
  color: var(--color-primary);
}

.NewsLetterSubscription_checkbox__FZ2om label button {
  text-decoration: underline;
  text-decoration-color: color-mix(in srgb, currentColor, transparent);
  text-decoration-thickness: 1px;
}

.FooterPanel_footerPanel__LjghC {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  gap: 0;
  flex-wrap: nowrap;
  padding: 32px;
}

.FooterPanel_footerPanel__LjghC > * {
  flex: 1 1 auto;
  min-height: 240px;
}

.FooterPanel_title__d4orQ {
  order: 2;
  padding: 1rem;
  background-color: var(--color-neutral-light);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.FooterPanel_copyright__fXL_e {
  font-size: var(--font-size-S);
}

.FooterPanel_title__d4orQ > h2 {
  text-align: right;
  line-height: 0.8;
}

.FooterPanel_title__d4orQ > div:first-child {
  font-family: var(--font-serif);
  font-size: 1rem;
  line-height: 0.8;
  text-align: right;
}

.FooterPanel_title__d4orQ > div:last-child {
  color: #0007;
}

.FooterPanel_links__nVjT7 {
  order: 3;
  display: grid;
  grid-template-rows: repeat(6, 1fr);
  padding: 0 12px;
  gap: 6px;
  font-size: 18px;
  min-width: 240px;
}

.FooterPanel_links__nVjT7 a {
  border: none;
  display: inline-flex;
  cursor: pointer;
  padding: 0.5em 0.5em;
  font-size: 1rem;
}

.FooterPanel_links__nVjT7 > div.FooterPanel_section__b2a94 {
  font-size: var(--font-size-S);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 2em;
  padding: 0;
  margin: 0;
  border-bottom: 1px solid var(--color-neutral);
  box-shadow: 0 0 inset var(--color-primary);
  transition: 0.5s box-shadow;
}

.FooterPanel_links__nVjT7 > div.FooterPanel_section__b2a94 > button {
  font-size: var(--font-size-S);
}

.FooterPanel_links__nVjT7 > div.FooterPanel_section__b2a94:hover {
  box-shadow: 0 -4px inset var(--color-primary);
  border-bottom: 1px solid var(--color-primary);
}

.FooterPanel_links__nVjT7 > div.FooterPanel_section__b2a94:first-child {
  border-top: none;
}

.FooterPanel_links__nVjT7 > div.FooterPanel_section__b2a94 > a {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
}

.FooterPanel_links__nVjT7 > div.FooterPanel_section__b2a94 > button {
  cursor: pointer;
  border-radius: 999vmax;
  padding: 0 0.3em;
}

.FooterPanel_links__nVjT7 > * {
  flex: 1 1 auto;
}

.FooterPanel_links__nVjT7 div.FooterPanel_socialmedia__uajEv a > span {
  display: none;
}

.FooterPanel_subscribe__ztH1S {
  order: 4;
}

@media (max-width: 1000px) {
  .FooterPanel_footerPanel__LjghC {
    flex-wrap: wrap;
    padding: 16px;
  }

  .FooterPanel_title__d4orQ,
  .FooterPanel_links__nVjT7 {
    width: 50%;
  }

  .FooterPanel_subscribe__ztH1S {
    order: 1;
    margin: 16px 0;
    min-width: 100%;
  }
}

@media (max-width: 480px) {
  .FooterPanel_footerPanel__LjghC > .FooterPanel_subscribe__ztH1S {
    min-height: auto;
    height: auto;
    gap: var(--gap-M);
    margin: 8px;
    min-width: calc(100% - 16px);
  }

  .FooterPanel_footerPanel__LjghC .FooterPanel_links__nVjT7 {
    width: 100%;
  }

  .FooterPanel_footerPanel__LjghC .FooterPanel_title__d4orQ {
    order: 4;
    max-height: 6em;
    min-height: 6em;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .FooterPanel_footerPanel__LjghC .FooterPanel_title__d4orQ > h2 {
    max-width: 6em;
  }

  .FooterPanel_footerPanel__LjghC {
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    padding: 0;
  }
}

div.PopupMenu_popupMenu__ZnhkC {
  cursor: pointer;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  z-index: 9999;
  pointer-events: none;
  opacity: 1;
  background-color: #fff;
  transition: all 0.2s;
  transform: translateX(100%);
}

div.PopupMenu_popupMenu__ZnhkC > menu {
  position: absolute;
  left: 1rem;
  top: 1rem;
  right: 1rem;
  bottom: 1rem;
  width: auto;
  height: auto;
  font-size: 7vmin;
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 0;
  background-color: #fff;
  padding: 1rem;
  border: 1px solid var(--color-neutral);
  width: calc(100% - 2rem);
  height: calc(100% - 2rem);
  max-height: calc(100% - 2rem);
}

div.PopupMenu_popupMenu__ZnhkC > menu > header {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
}

div.PopupMenu_popupMenu__ZnhkC > menu > header > button.PopupMenu_close__T5CRs {
  font-size: 1em;
}

div.PopupMenu_popupMenu__ZnhkC > menu > section {
  margin: 2rem 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0;
  position: absolute;
  left: 0;
  top: 3em;
  bottom: 0;
  width: 100%;
  height: auto;
}

div.PopupMenu_popupMenu__ZnhkC.PopupMenu_show__UBfOB {
  opacity: 1;
  pointer-events: all;
  transform: translateX(0);
}

div.PopupMenu_popupMenu__ZnhkC.PopupMenu_show__UBfOB section > .PopupMenu_link__bpzYH {
  cursor: pointer;
  padding: 0.2em 0.5em;
  margin: 0;
  margin-top: -1px;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
  font-family: var(--font-sans-serif);
  font-size: 1em;
}

@media (min-width: 951px) {
  div.PopupMenu_popupMenu__ZnhkC.PopupMenu_show__UBfOB {
    opacity: 0;
    pointer-events: none;
  }
}

.Menu_menuContainer__Ub7m7 {
  --size-button: 160px;
  --size-header: var(--size-button);

  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: var(--font-size-M);
  color: #fffe;
  font-size: var(--font-size-M);
  z-index: 999;
  background-color: transparent;
  transition: all 0.6s;
}

.Menu_menuContainer__Ub7m7.Menu_stuck___EywR {
  background-color: color-mix(in srgb, var(--color-primary) 90%, transparent);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
}

.Menu_menuContainer__Ub7m7 .Menu_logo__zq9RM {
  flex: 0 0 auto;
  max-width: 8em;
}

.Menu_menuContainer__Ub7m7 .Menu_logo__zq9RM h2 {
  text-align: right;
  line-height: 0.8;
}

.Menu_menuContainer__Ub7m7 > div.Menu_items__VnA36 {
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  gap: 0;
  justify-content: flex-end;
  align-items: flex-start;
  line-height: 2;
}

.Menu_menuContainer__Ub7m7 div.Menu_items__VnA36 a {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  margin: 0;
  padding: 0 1em;
}

.Menu_menuContainer__Ub7m7 div.Menu_items__VnA36 a::after {
  content: '';
  display: block;
  height: 4px;
  width: 48px;
  background-color: #fffe;
  border-radius: 999vmax;
  opacity: 0;
  transition: all 0.5s;
}

.Menu_menuContainer__Ub7m7 div.Menu_items__VnA36 a:hover::after {
  opacity: 0.5;
}

.Menu_menuContainer__Ub7m7 div.Menu_items__VnA36 a.Menu_selected__jIr6B::after {
  opacity: 1;
}

.Menu_menuContainer__Ub7m7 div.Menu_items__VnA36 a.Menu_loginButton__qTRU1::after {
  display: none;
}

.Menu_menuContainer__Ub7m7 div.Menu_items__VnA36 a.Menu_loginButton__qTRU1 {
  border: 1px solid currentColor;
  border-radius: 4px;
  color: var(--color-primary);
  background-color: #fffe;
  transition: all 0.6s;
}

.Menu_menuContainer__Ub7m7 div.Menu_items__VnA36 a.Menu_loginButton__qTRU1:hover {
  background-color: var(--color-primary);
  color: #fffe;
}

.Menu_menuContainer__Ub7m7 > div.Menu_hamburger__aLL8B {
  display: none;
}

.Menu_menuContainer__Ub7m7 > div.Menu_hamburger__aLL8B > button {
  font-size: var(--font-size-L);
}

@media (max-width: 950px) {
  .Menu_menuContainer__Ub7m7 > div.Menu_items__VnA36 {
    display: none;
  }

  .Menu_menuContainer__Ub7m7 div.Menu_hamburger__aLL8B {
    display: block;
  }
}

.Error_error__2nuL_ {
  color: #fffe;
  background-color: #f00;
  border: 2px solid #fff;
  box-shadow: 0 0.25em 0.5em #0005;
  padding: 1em;
  margin: 1em;
  white-space: pre-wrap;
}

.Error_error__2nuL_ pre {
  font-size: var(--font-size-S);
}

.Title_title__ZgyeB {
  width: 100%;
  z-index: 1;
}

.Title_title__ZgyeB > div {
  content: '';
  display: block;
  height: 2px;
  width: calc(100% - 24px);
  font-size: 0;
  background-color: var(--color-neutral);
  margin: 0;
  padding: 0;
}

.Title_flex__9IiS3 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.Title_flex__9IiS3 > h1 {
  flex: 1 1 auto;
}

.Title_flex__9IiS3 > div {
  flex: 0 0 auto;
}

a.TemporaryGoToLabButton_temporaryGoToLabButton__kpK4s {
  all: unset;
  cursor: pointer;
  font-family: var(--font-sans-serif);
  font-size: var(--font-size-M);
  position: relative;
  min-height: 128px;
  background-color: var(--color-primary);
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  gap: 0;
  margin: 1em 0;
  padding: 0 2em;
}

.TemporaryGoToLabButton_temporaryGoToLabButton__kpK4s > * {
  display: block;
  z-index: 2;
}

.TemporaryGoToLabButton_temporaryGoToLabButton__kpK4s > strong {
  color: #fffe;
  font-size: var(--font-size-L);
  font-weight: 400;
}

.TemporaryGoToLabButton_temporaryGoToLabButton__kpK4s > div {
  color: #fffb;
  font-size: var(--font-size-S);
}

.TemporaryGoToLabButton_temporaryGoToLabButton__kpK4s > .TemporaryGoToLabButton_image__ujc__ {
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

h1.SanityContentTitle_sanityContentTitle__M4pJT {
  margin-top: 1em;
}

.spacer_spacer__V9IXD {
  content: '';
  display: block;
}

.spacer_spacer__V9IXD.spacer_small__jLUBM {
  height: 8px;
}

.spacer_spacer__V9IXD.spacer_medium__cZm1R {
  height: 24px;
}

.spacer_spacer__V9IXD.spacer_large__ZneHd {
  height: 72px;
}

.Text_text__QJLyG.Text_raw__LNAdC p {
  all: unset;
}

.Text_text__QJLyG p {
  font-size: var(--font-size-M);
  line-height: 1.55;
}

.Text_maxLines__VuWuF,
.Text_maxLines__VuWuF p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: var(--custom-max-lines);
}

.SanityContentParagraph_sanityContentParagraph__oL12_ {
  margin-bottom: 42px;
}

ul.SanityContentItems_sanityContentItems__4f09d > li {
  list-style-type: '⎯';
  padding-inline-start: 1ch;
  margin-bottom: 1em;
  clear: both;
}

ul.SanityContentItems_sanityContentItems__4f09d > li h3 {
  font-size: var(--font-size-M-plus);
  font-family: var(--font-serif);
}

.SanityContentItems_vignette__OKfzG {
  width: 120px;
  height: 120px;
  float: left;
  margin: 0 0.5em 0.5em 0;
}

.swipeable-cards-list_swipeableCardsList__fFss_ {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  margin: var(--gap-L) 0;
  gap: var(--gap-S);
}

.swipeable-cards-list_scroll__WqoYJ {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: var(--custom-gap, 48px);
  flex-wrap: nowrap;
  scroll-behavior: smooth;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  padding-bottom: 24px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.swipeable-cards-list_swipeableCardsList__fFss_ > footer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
  padding: 0;
  padding-right: 16px;
}

.swipeable-cards-list_swipeableCardsList__fFss_ > footer.swipeable-cards-list_onlyOnSmallScreen__NgGSo {
  display: none;
}

@media (max-width: 480px) {
  .swipeable-cards-list_swipeableCardsList__fFss_ > footer.swipeable-cards-list_onlyOnSmallScreen__NgGSo {
    display: flex;
  }

  .swipeable-cards-list_swipeableCardsList__fFss_ > footer {
    flex-direction: column;
    gap: 2em;
  }

  .swipeable-cards-list_swipeableCardsList__fFss_ > footer > div {
    order: 2;
  }
}

.swipeable-cards-list_swipeableCardsList__fFss_ nav {
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  gap: 8px;
  order: 1;
}

.swipeable-cards-list_swipeableCardsList__fFss_ nav > button.swipeable-cards-list_page__8k01O {
  content: '';
  width: 24px;
  height: 6px;
  border-radius: 99vmax;
  background-color: #0004;
}

.swipeable-cards-list_swipeableCardsList__fFss_ nav > button.swipeable-cards-list_page__8k01O.swipeable-cards-list_selected__9OJf4 {
  background-color: var(--color-primary);
}

.swipeable-cards-list_swipeableCardsList__fFss_ nav > button.swipeable-cards-list_arrow__tw99S {
  width: 3em;
  display: grid;
  place-items: center;
}

.FromCellToBrain_column___DL1J {
  flex: 0 0 auto;
  padding: 8px;
  --width: min(calc(100vw - 16px), 370px);
  width: var(--width);
  min-width: var(--width);
  scroll-snap-stop: always;
}

.FromCellToBrain_column___DL1J > h2 {
  display: block;
  text-transform: uppercase;
  font-family: var(--font-sans-serif);
  font-size: var(--font-size-L);
  font-size: clamp(20px, 2vw, 22px);
  font-weight: 400;
  letter-spacing: 0.02em;
  color: var(--color-neutral);
  width: 100%;
  background-color: #fff;
  /* position: sticky;
  top: var(--custom-menu-height, 0px); */
  left: auto;
  right: auto;
  bottom: auto;
}

.FromCellToBrain_card__Z5L87 {
  height: 240px;
  margin: 16px 0;
  padding: 8px;
  border: 1px solid var(--color-neutral);
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: 1fr 1fr;
  overflow: hidden;
}

.FromCellToBrain_card__Z5L87 > h3 {
  font-size: var(--font-size-L);
}

.FromCellToBrain_image__VjcP3 {
  width: 120px;
  height: 120px;
  min-width: 120px;
  min-height: 120px;
  border-radius: 50%;
  overflow: hidden;
}

.FromCellToBrain_description__hy4MT {
  grid-column: 1/-1;
  font-size: var(--font-size-S);
  place-self: start;
}

@media (max-width: 480px) {
  .FromCellToBrain_column___DL1J {
    --width: calc(100vw - 16px);
  }
  .FromCellToBrain_image__VjcP3 {
    display: none;
  }
  .FromCellToBrain_card__Z5L87 {
    height: 160px;
  }
  .FromCellToBrain_description__hy4MT {
    font-size: var(--font-size-M);
  }
  .FromCellToBrain_card__Z5L87 > h3 {
    font-size: var(--font-size-XL);
  }
}

.Hero_hero__aQDDb {
  position: relative;
  padding-top: 50vh;
  overflow: hidden;
}

.Hero_hero__aQDDb > .Hero_body__nRj8r {
  background: #fff;
  z-index: 2;
  padding: 0 var(--colM);
  padding-bottom: 3em;
}

.Hero_background__jBrPn {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
  aspect-ratio: var(--custom-aspect-ratio, 1/1);
  z-index: 1;
}

.Hero_hero__aQDDb button.Hero_button__dNcIO {
  all: unset;
  cursor: pointer;
  padding: 1em 2em;
  border: 1px solid currentColor;
  transition: all 0.4s;
  margin-top: 1em;
}

.Hero_hero__aQDDb.Hero_light__wS9A3 button.Hero_button__dNcIO:hover {
  color: #fff;
  background-color: var(--color-primary);
}

button.MissionStatement_missionStatement__k5HA_ {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  gap: 0;
  max-width: 1200px;
  cursor: pointer;
  border-radius: 16px;
  box-shadow: 0 16px 32px #0005;
  background-color: var(--color-neutral-light);
  overflow: hidden;
  margin: 5rem 0;
}

.MissionStatement_missionStatement__k5HA_ > div.MissionStatement_text__A84gM {
  display: flex;
  flex-direction: column;
  gap: 0;
  justify-content: center;
  align-items: stretch;
  padding: 24px;
}

.MissionStatement_missionStatement__k5HA_ > div.MissionStatement_text__A84gM > * {
  flex: 0 0 auto;
}

.MissionStatement_missionStatement__k5HA_ > div.MissionStatement_text__A84gM > h2 {
  font-family: var(--font-serif);
  font-size: var(--font-size-XL);
  line-height: 1.2;
  margin-left: 2em;
}

.MissionStatement_missionStatement__k5HA_ > div.MissionStatement_background__FtBj5 {
  position: relative;
  background-color: var(--color-primary);
  display: grid;
  place-items: center;
  color: #fff;
  font-size: 48px;
  min-width: 240px;
  min-height: 240px;
}

.MissionStatement_missionStatement__k5HA_ > div.MissionStatement_background__FtBj5 .MissionStatement_image__JQqgi {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: 1;
}

.MissionStatement_missionStatement__k5HA_ > div.MissionStatement_background__FtBj5 .MissionStatement_icon__hjYp3 {
  z-index: 2;
}

@media (max-width: 800px) {
  button.MissionStatement_missionStatement__k5HA_ {
    flex-direction: column;
  }
}

button.NewsCard_newsCard__78KsG {
  all: unset;
  position: relative;
  width: min(640px, calc(100vw - 8px));
  height: 240px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  gap: 0;
  flex: 0 0 auto;
  scroll-snap-stop: always;
  scroll-snap-align: start;
  cursor: pointer;
}

button.NewsCard_newsCard__78KsG > div.NewsCard_content__Tiema {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 8px;
  padding-right: 16px;
  z-index: 1;
  transition: all 0.6s;
}

button.NewsCard_newsCard__78KsG:hover > div.NewsCard_content__Tiema {
  background-color: var(--color-primary);
  color: #ffe;
}

button.NewsCard_newsCard__78KsG > div.NewsCard_content__Tiema > h2 {
  line-height: 1.2;
}

button.NewsCard_newsCard__78KsG > div.NewsCard_content__Tiema > * {
  flex: 0 0 auto;
}

button.NewsCard_newsCard__78KsG > div.NewsCard_content__Tiema > small {
  flex: 1 1 auto;
  color: #0008;
  font-weight: normal;
}

button.NewsCard_newsCard__78KsG:hover > div.NewsCard_content__Tiema > small {
  color: #fff8;
}

button.NewsCard_newsCard__78KsG > div.NewsCard_content__Tiema > p {
  margin: 0;
  font-size: var(--font-size-M);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

button.NewsCard_newsCard__78KsG > div.NewsCard_picture__UYuhW {
  z-index: 0;
  width: 240px;
  flex: 0 0 auto;
  position: relative;
}

button.NewsCard_newsCard__78KsG > div.NewsCard_picture__UYuhW > * {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: top center;
     object-position: top center;
}

@media (max-width: 600px) {
  button.NewsCard_newsCard__78KsG > div.NewsCard_picture__UYuhW {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  button.NewsCard_newsCard__78KsG > div.NewsCard_content__Tiema {
    padding: 1em;
    background-color: color-mix(in srgb, var(--color-primary) 66%, transparent);
    color: #fffe;
  }

  button.NewsCard_newsCard__78KsG > div.NewsCard_content__Tiema > small {
    color: #fffc;
  }

  button.NewsCard_newsCard__78KsG:hover > div.NewsCard_content__Tiema > small {
    color: #fffc;
  }
}

.Button_button__Wahjt {
  display: flex;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.Button_button__Wahjt.Button_end__vNmMF {
  justify-content: flex-end;
}

.Button_button__Wahjt.Button_center__4FHIp {
  justify-content: flex-center;
}

.Button_button__Wahjt.Button_start__1mCao {
  justify-content: flex-start;
}

div.Button_core__TokbW {
  margin: 0;
  padding: 16px 20px;
  border: 1px solid var(--color-neutral);
  width: 365px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  max-width: calc(100vw - 3rem);
}

div.Button_core__TokbW > div:first-child {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.25rem;
  margin: 0;
  padding: 0;
}

div.Button_core__TokbW > div > * {
  margin: 0;
  padding: 0;
}

div.Button_core__TokbW > div:last-child {
  line-height: 125%;
  font-size: 2.25rem;
  margin: 0;
  padding: 0;
  leading-trim: both;
  text-edge: cap;
}

@media (max-width: 640px) {
  div.Button_core__TokbW {
    width: 320px;
    padding: 12px 16px;
  }
  div.Button_core__TokbW > div:first-child {
    font-size: 1.15rem;
  }
  div.Button_core__TokbW > div:last-child {
    font-size: 2rem;
  }
}

@media (max-width: 480px) {
  div.Button_core__TokbW {
    width: 240px;
    padding: 8px 12px;
  }
  div.Button_core__TokbW > div:first-child {
    font-size: 1rem;
  }
  div.Button_core__TokbW > div:last-child {
    font-size: 1.5rem;
  }
}

div.Video_video__nMZi_ {
  position: relative;
  margin: 0;
  padding: 0;
}

div.Video_video__nMZi_ > video {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.Video_pointer__f_QtE {
  cursor: pointer;
}

.OurFoundations_ourFoundations__NOI3p {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 20vw;
  grid-auto-rows: auto;
  grid-template-areas:
    'S P'
    'L P';
  gap: 0;
  place-items: stretch;
  overflow-x: hidden;
  margin: 1em 0;
}

.OurFoundations_section__23UXJ {
  grid-area: S;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  background-color: var(--color-neutral-light);
  padding: 2.5em;
}

.OurFoundations_picture__1Pmu3 {
  grid-area: P;
  position: relative;
  width: 100%;
  height: 100%;
}

.OurFoundations_picture__1Pmu3 > * {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.OurFoundations_section__23UXJ h2 {
  width: 100%;
  font-family: var(--font-serif);
  font-size: var(--font-size-L);
  line-height: 1.1;
  margin: 0;
  margin-bottom: 2em;
  max-width: none;
}

.OurFoundations_section__23UXJ h3 {
  font-size: var(--font-size-M);
  text-transform: uppercase;
  margin-bottom: 10px;
  font-family: var(--font-sans-serif);
}

.OurFoundations_section__23UXJ p {
  font-size: var(--font-size-M);
  margin: 0;
  padding: 0;
}

.OurFoundations_ourFoundations__NOI3p > footer {
  grid-area: L;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  /* Important: this gap plus the outline in the buttons make the border to collapse properly. */
  gap: 1px;
  border: 1px solid var(--color-neutral);
}

.OurFoundations_ourFoundations__NOI3p > footer > .OurFoundations_link__9yL0c {
  width: 100%;
  border: none;
  outline: 1px solid var(--color-neutral);
}

.OurFoundations_ourFoundations__NOI3p > footer > .OurFoundations_link__9yL0c > div {
  width: 100%;
  max-width: none;
  margin: 0;
  border: none;
  outline: none;
}

@media (max-width: 1000px) {
  .OurFoundations_ourFoundations__NOI3p {
    grid-template-areas:
      'S S'
      'L P';
  }

  .OurFoundations_ourFoundations__NOI3p > footer {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }

  .OurFoundations_section__23UXJ {
    padding: 2em;
  }
}

@media (max-width: 480px) {
  .OurFoundations_ourFoundations__NOI3p {
    grid-template-areas:
      'S'
      'P'
      'L';
    grid-template-columns: 1fr;
    grid-template-rows: auto 200px auto;
  }

  .OurFoundations_section__23UXJ {
    padding: 1em;
  }

  .OurFoundations_section__23UXJ h2 {
    font-size: 7vw;
  }

  .OurFoundations_section__23UXJ > div > img {
    display: none;
  }

  .OurFoundations_section__23UXJ > div {
    z-index: 1;
  }

  .OurFoundations_picture__1Pmu3 {
    width: 100%;
  }

  .OurFoundations_ourFoundations__NOI3p > footer > .OurFoundations_link__9yL0c {
    position: relative;
    justify-content: stretch;
    height: 5rem;
  }

  .OurFoundations_ourFoundations__NOI3p > footer > .OurFoundations_link__9yL0c > div {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

button.card_card__CxwPP {
  position: relative;
  border: 1px solid var(--color-neutral);
  padding: 1em;
  margin: 0 -1px -1px 0;
  display: grid;
  grid-template-rows: auto 1fr auto;
  width: var(--size-block, 256px);
  aspect-ratio: 1/1;
  overflow: hidden;
  cursor: default;
}

.card_comingSoon__ZSFbI {
  display: inline-block;
  padding: 0.25em 1em;
  color: #fffa;
  border: 1px solid currentColor;
  border-radius: 99vmax;
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
}

button.card_card__CxwPP .card_content__HNT1E {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  color: #fffe;
  z-index: 2;
}

.card_card__CxwPP h2,
.card_card__CxwPP h3 {
  margin: 0;
  color: #fffe;
}

.card_card__CxwPP h2 {
  position: relative;
  top: -4px;
  font-family: var(--font-serif);
}

.card_card__CxwPP h3 {
  font-weight: lighter;
}

button.card_card__CxwPP .card_background__ZsbrA {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.VirtualLabsPanel_triBlocks__mZ6QJ {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 0;
  --size-block: clamp(min(calc(100vw - 1rem), 400px), 30%, 600px);
  padding: 0 40px;
}

@media (max-width: 480px) {
  .VirtualLabsPanel_triBlocks__mZ6QJ {
    padding: 0;
  }
}

a.PortalCard_card__MyCLv {
  box-sizing: border-box;
  position: relative;
  border: 1px solid var(--color-neutral);
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  width: 640px;
  height: 260px;
  max-width: calc(100vw - 8px);
  margin: 0;
  padding: 0;
  transition: all 0.6s;
  overflow: hidden;
}

a.PortalCard_card__MyCLv .PortalCard_content__vO78b h2 {
  margin: 0;
  line-height: 1;
}

a.PortalCard_card__MyCLv:hover {
  color: #fffe;
  background-color: var(--color-primary);
}

a.PortalCard_card__MyCLv .PortalCard_content__vO78b {
  width: 100%;
  transition: all 0.6s;
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  background-image: linear-gradient(35deg, #fff, #fffd 60%, #fff0);
  z-index: 1;
}

a.PortalCard_card__MyCLv:hover .PortalCard_content__vO78b {
  background: var(--color-primary);
}

a.PortalCard_card__MyCLv > div.PortalCard_content__vO78b > div {
  line-height: 1.2;
}

.PortalCard_content__vO78b h2 {
  font-family: var(--font-serif);
}

a.PortalCard_card__MyCLv .PortalCard_pictureContainer__x__Bu {
  position: relative;
  flex: 0 0 auto;
  width: 260px;
  height: 100%;
  overflow: hidden;
}

a.PortalCard_card__MyCLv .PortalCard_picture__7Qmet {
  overflow: hidden;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: all 0.6s;
}

a.PortalCard_card__MyCLv:hover .PortalCard_picture__7Qmet {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: all 0.6s;
  transform: scale(1.2) rotate(10deg);
}

@media (max-width: 800px) {
  a.PortalCard_card__MyCLv .PortalCard_pictureContainer__x__Bu {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }

  a.PortalCard_card__MyCLv {
    height: 300px;
  }

  a.PortalCard_card__MyCLv .PortalCard_content__vO78b {
    color: #fffe;
    background: color-mix(in srgb, var(--color-primary), transparent);
  }
}

.PortalsPanel_portalsPanel__mm9D_ {
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
  gap: 16px;
  margin: 1em 0;
}

.ContributorsNavigation_contributorsPanel__nF1J9 {
  --margin-letter: var(--custom-margin-letter, 12px);
  --size-letter: var(--custom-size-letter, 32px);

  max-width: min(740px, calc(100vw - 2px));
  margin-bottom: 3em;
}

.ContributorsNavigation_contributorsPanel__nF1J9 header {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.ContributorsNavigation_contributorsPanel__nF1J9 header > div p {
  all: unset;
}

.ContributorsNavigation_thanks__M2BBk {
  font-family: var(--font-serif);
  background-color: var(--color-neutral-light);
  line-height: 0.8;
  font-size: var(--font-size-L);
  padding: 1.5rem;
  text-align: right;
}

.ContributorsNavigation_more__0yFE5 {
  background-color: var(--color-primary);
  color: #fff;
  padding: 1.5rem;
  font-size: var(--font-size-M);
}

.ContributorsNavigation_pages__weZY4 {
  grid-column: 2/4;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
  padding: 0;
  padding-top: 1em;
  padding-bottom: 2em;
}

.ContributorsNavigation_chevron__IfS2W {
  font-size: 0.6rem;
}

.ContributorsNavigation_pages__weZY4 div.ContributorsNavigation_container__1dqNU {
  position: relative;
  width: 100%;
  height: calc(1.5 * var(--size-letter));
}

.ContributorsNavigation_pages__weZY4 div.ContributorsNavigation_container__1dqNU::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: calc(2 * var(--margin-letter));
  height: 100%;
  background: linear-gradient(to right, #fff, #fff0);
  z-index: 1;
}

.ContributorsNavigation_pages__weZY4 div.ContributorsNavigation_container__1dqNU::after {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  width: calc(2 * var(--margin-letter));
  height: 100%;
  background: linear-gradient(to left, #fff, #fff0);
  z-index: 1;
}

.ContributorsNavigation_pages__weZY4 div.ContributorsNavigation_letters__37PGy {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-x: scroll;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  font-size: 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0;
  flex-wrap: nowrap;
  padding: 0 var(--margin-letter);
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.ContributorsNavigation_pages__weZY4 div.ContributorsNavigation_letters__37PGy::-webkit-scrollbar {
  display: none;
}

.ContributorsNavigation_pages__weZY4 button {
  display: inline-block;
  cursor: pointer;
  border: 1px solid var(--color-neutral);
  display: grid;
  place-items: center;
  width: 1.5em;
  height: 1.5em;
  flex: 0 0 auto;
}

.ContributorsNavigation_pages__weZY4 > button {
  border: none;
}

.ContributorsNavigation_pages__weZY4 .ContributorsNavigation_letter__am8DA {
  padding: 0 var(--margin-letter);
  z-index: 0;
}

.ContributorsNavigation_pages__weZY4 .ContributorsNavigation_letter__am8DA button {
  width: var(--size-letter, 32px);
  height: var(--size-letter, 32px);
  box-sizing: border-box;
  font-size: calc(0.5 * var(--size-letter, 32px));
}

.ContributorsNavigation_pages__weZY4 .ContributorsNavigation_letter__am8DA button[disabled] {
  cursor: default;
  pointer-events: none;
  background-color: var(--color-primary);
  color: #fff;
}

@media (max-width: 720px) {
  .ContributorsNavigation_contributorsPanel__nF1J9 header {
    grid-template-columns: 1fr;
  }

  .ContributorsNavigation_contributorsPanel__nF1J9 .ContributorsNavigation_thanks__M2BBk {
    font-size: 2em;
  }
}

.CenteredColumn_centeredColumn___3d2N {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 0;
}

.CenteredColumn_centeredColumn___3d2N > div {
  grid-column: 2/3;
}

.ContributorsList_contributorsList__edUrM {
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
  gap: 4em 3.2em;
  padding: 0 4rem;
}

@media (max-width: 640px) {
  .ContributorsList_contributorsList__edUrM {
    padding: 0;
  }
}

.ContributorsList_contributorsList__edUrM > div {
  width: min(calc(100vw - 2px), 360px);
  max-height: calc(4 * var(--font-size-L));
}

.ContributorsList_contributorsList__edUrM > div > .ContributorsList_name__8Yqmy {
  font-family: var(--font-serif);
  font-size: var(--font-size-M-plus);
  line-height: 1;
  padding: 0.4em 0.5em;
  background-color: var(--color-neutral-light);
}

.ContributorsList_contributorsList__edUrM > div > .ContributorsList_links__we6cK {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.ContributorsList_contributorsList__edUrM > div > .ContributorsList_links__we6cK > a {
  font-size: var(--font-size-M);
}

.ContributorsList_links__we6cK a {
  display: inline-block;
  cursor: pointer;
  font-size: 1rem;
  border: solid 1px var(--color-neutral);
  padding: 6px 12px;
}

button.ContributorsList_loadMore__fh72f {
  display: inline-block;
  padding: 0.5em 2em;
  cursor: pointer;
  border: 1px solid var(--color-neutral);
  border-radius: 99vmax;
  margin-top: 3rem;
}

.TeamMember_teamMember__4W2Wp {
  display: grid;
  grid-template-rows: 1fr auto auto;
  gap: 0;
  width: min(100vw, 300px);
  height: 240px;
}

.TeamMember_teamMember__4W2Wp.TeamMember_big__SvBaA {
  width: min(100vw, 320px);
  height: 360px;
}

.TeamMember_teamMember__4W2Wp > div.TeamMember_image__4Gnsy {
  position: relative;
  content: '';
  background-size: cover;
  background-color: var(--color-primary);
}

.TeamMember_teamMember__4W2Wp > div.TeamMember_image__4Gnsy > img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  opacity: 0;
  transition: opacity 1s;
}

.TeamMember_teamMember__4W2Wp > div.TeamMember_image__4Gnsy > img.TeamMember_ready__P2bu_ {
  opacity: 1;
}

.TeamMember_teamMember__4W2Wp > div.TeamMember_name__OLKoN {
  font-family: var(--font-serif);
  font-size: var(--font-size-M);
  background-color: var(--color-neutral-light);
  padding: 0 1em;
  margin: 0;
  line-height: 1;
  display: grid;
  place-items: center;
  height: 3em;
}

.TeamMember_teamMember__4W2Wp.TeamMember_big__SvBaA > div.TeamMember_name__OLKoN {
  font-size: var(--font-size-L);
}

.TeamMember_teamMember__4W2Wp > div.TeamMember_profile__EdAKb {
  text-align: right;
  font-size: var(--font-size-M);
}

.CompanyMembers_people__6dvaX {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  align-items: stretch;
}

.CompanyMembers_board__qouAv {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  align-items: stretch;
  margin-bottom: 3rem;
}

a.EmailButton_emailButton__PiMCD {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  border: 1px solid var(--color-neutral);
  padding: 48px;
  cursor: pointer;
  width: min(500px, calc(100vw - 8px));
  max-width: calc(100vw - 8px);
}

.EmailButton_emailButton__PiMCD > big {
  white-space: nowrap;
  font-size: var(--font-size-L);
  font-family: var(--font-serif);
  margin: 0;
}

@media (max-width: 640px) {
  a.EmailButton_emailButton__PiMCD {
    padding: 16px 8px;
  }
}

.Email_widgetEmail__cgD0U {
}

.select-currency_selectCurrency__qORkv {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 1em;
  padding-right: 2em;
}

div.select-currency_selectCurrency__qORkv > div.select-currency_select__sL1sT {
  font-size: var(--font-size-M);
  height: 3em;
  width: 200px;
}

div.select-currency_selectCurrency__qORkv > div.select-currency_select__sL1sT > div.ant-select-selector {
  border-radius: 0px;
  color: var(--color-primary);
  font-size: var(--font-size-M);
  font-family: var(--font-sans-serif);
}

.contact-us_contactUs__UBSwK {
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  min-height: 100px;
}

.plan-header_planHeader__lAZ8p {
  margin: 16px;
  padding: 32px;
  background-color: #fff;
  box-shadow: 0 0.75em 1.5em #0003;
  border-radius: 6px;
  font-size: var(--font-size-M);
}

.plan-header_planHeader__lAZ8p .plan-header_priceHeader__ngDKx em {
  color: var(--color-neutral);
  font-variant: normal;
  font-style: normal;
}

.plan-header_planHeader__lAZ8p hr {
  margin: 1em 0;
}

.plan-header_planHeader__lAZ8p > h1 {
  margin: 0;
  font-size: var(--font-size-XL);
}

.plan-header_planHeader__lAZ8p small {
  font-size: var(--font-size-S);
}

.plan-header_discount__bGyfr {
  color: var(--color-neutral);
  text-decoration: line-through;
  text-decoration-color: currentColor;
}

.plan-header_hide__RQV8U {
  display: none;
}

.plan-header_show__QkOWh {
  display: block;
}

.plan-header_planHeader__lAZ8p ul {
  margin: 1em 0 1em 1em;
}

.plan-header_planHeader__lAZ8p ul > li::marker {
  content: '+';
}

.plan-header_pill__f_RXx {
  color: var(--color-primary);
  font-size: var(--font-size-M);
  height: 2em;
  padding: 0 0.5em;
  border-radius: 99vmax;
  border: 1px solid var(--color-primary);
  margin: 1em 0;
  display: grid;
  place-items: center;
}

.plan-header_price__LrTmJ {
  font-size: var(--font-size-L);
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0;
}

.plan-header_price__LrTmJ > small {
  font-size: var(--font-size-M);
}

.FeatureCell_featureCell__Ma_oS {
  color: #0a0;
  fill: currentColor;
}

.FeatureCell_featureCell__Ma_oS.FeatureCell_unavailable__4suTj {
  color: var(--color-neutral);
  fill: currentColor;
}

svg.FeatureCell_featureCell__Ma_oS {
  width: 1.5em;
  height: 1.5em;
}

button.FeatureCell_tooltip__KjEgz {
  all: unset;
  color: #0a0;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.5em;
  position: relative;
}

button.FeatureCell_tooltip__KjEgz::after {
  pointer-events: none;
  content: attr(data-tooltip);
  position: absolute;
  right: 0;
  top: 0;
  width: -moz-max-content;
  width: max-content;
  max-width: 80vw;
  overflow: hidden;
  z-index: 999998;
  background-color: var(--color-primary);
  color: #fffe;
  padding: 1.5em;
  transform: translateY(calc(-100% - 12px)) translateX(8px);
  border-radius: 4px;
  transition: opacity 0.3s;
  opacity: 0;
}

button.FeatureCell_tooltip__KjEgz::before {
  pointer-events: none;
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  width: 0;
  height: 0;
  border: 16px solid;
  border-color: var(--color-primary) transparent transparent transparent;
  z-index: 999999;
  box-sizing: content-box;
  transform: scaleX(0.5) translate(8px, -12px);
  transition: opacity 0.3s;
  opacity: 0;
}

button.FeatureCell_tooltip__KjEgz:hover::before,
button.FeatureCell_tooltip__KjEgz:hover::after,
button.FeatureCell_tooltip__KjEgz:active::before,
button.FeatureCell_tooltip__KjEgz:active::after {
  opacity: 1;
}

.FeatureLine_featureLine__ZP1fq {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.5em;
  min-height: 2em;
}

.FeatureLine_unavailable__YCTq6 {
  color: var(--color-neutral);
}

.FeatureBloc_featureBloc__D2Xbl {
  padding: 16px;
}

.FeatureBloc_title__lpHMP {
  color: var(--color-neutral);
  text-transform: uppercase;
  grid-column: 1/-1;
}

.FeatureBloc_title__lpHMP .FeatureBloc_capsule__WOPHL {
  padding: 0 0.5em;
  margin-left: 1em;
  border-radius: 99vmax;
  text-transform: none;
  border: 1px solid var(--color-neutral);
}

@media (min-width: 481px) {
  .small-screen_smallScreen__5B5zh {
    display: none;
  }
}

.small-screen_smallScreen__5B5zh > header {
  all: unset;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  gap: 1em;
  font-size: var(--font-size-L);
  cursor: pointer;
  padding: 0.5em 0;
  background: #fffe;
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  z-index: 999999;
  position: sticky;
  top: var(--custom-menu-height);
}

.small-screen_smallScreen__5B5zh > header > button {
  all: unset;
  font-size: var(--font-size-L);
  text-transform: uppercase;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 0em;
}

.small-screen_smallScreen__5B5zh > header > button::after {
  content: '';
  width: 2em;
  height: 0.25em;
  border-radius: 99vmax;
  background: var(--color-primary);
  transition: all 0.3s;
  opacity: 0;
}

.small-screen_smallScreen__5B5zh > header > button[disabled]::after {
  opacity: 1;
}

.PlanHeader_planHeader__rsIyJ small {
  font-size: var(--font-size-S);
}

.PlanHeader_discount__Uo_nw {
  color: var(--color-neutral);
  text-decoration: line-through;
  text-decoration-color: currentColor;
}

.PlanHeader_hide__PSbcm {
  opacity: 0;
}

.PlanHeader_show__xvMET {
  opacity: 1;
}

.PlanHeader_planHeader__rsIyJ ul {
  margin-top: 2em;
  margin-bottom: 3em;
}

.PlanHeader_planHeader__rsIyJ ul > li::marker {
  content: '+';
}

.PlanHeader_pill__skJQr {
  content: 'Launch';
  color: var(--color-primary);
  font-size: var(--font-size-S);
  height: 1.5em;
  padding: 0 0.5em;
  border-radius: 99vmax;
  border: 1px solid var(--color-neutral);
  margin-left: 1em;
  display: grid;
  place-items: center;
}

.PlanHeader_price__yjRq3 {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0;
}

.PlanHeader_priceHeader__fnwmw {
  min-height: 200px;
}

.FeatureCell_featureCell__Wke_9 {
  color: #0a0;
  fill: currentColor;
  place-self: start;
}

.FeatureCell_featureCell__Wke_9.FeatureCell_unavailable__eVtRm {
  color: var(--color-neutral);
  fill: currentColor;
}

svg.FeatureCell_featureCell__Wke_9 {
  width: 1.5em;
  height: 1.5em;
}

button.FeatureCell_tooltip__cAfR2 {
  all: unset;
  color: #0a0;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.5em;
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
}

button.FeatureCell_tooltip__cAfR2::after {
  pointer-events: none;
  content: attr(data-tooltip);
  position: absolute;
  right: 0;
  top: 0;
  width: -moz-max-content;
  width: max-content;
  max-width: min(300px, 80vw);
  overflow: hidden;
  z-index: 999998;
  background-color: var(--color-primary);
  color: #fffe;
  padding: 1.5em;
  transform: translateY(calc(-100% - 12px)) translateX(8px);
  border-radius: 4px;
  transition: opacity 0.3s;
  opacity: 0;
}

button.FeatureCell_tooltip__cAfR2::before {
  pointer-events: none;
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  width: 0;
  height: 0;
  border: 16px solid;
  border-color: var(--color-primary) transparent transparent transparent;
  z-index: 999999;
  box-sizing: content-box;
  transform: scaleX(0.5) translate(8px, -12px);
  transition: opacity 0.3s;
  opacity: 0;
}

button.FeatureCell_tooltip__cAfR2:hover::before,
button.FeatureCell_tooltip__cAfR2:hover::after,
button.FeatureCell_tooltip__cAfR2:active::before,
button.FeatureCell_tooltip__cAfR2:active::after {
  opacity: 1;
}

.FeatureLine_unavailable__Ysp4V {
  color: var(--color-neutral);
}

.FeatureBloc_title__iXNhm {
  color: var(--color-neutral);
  text-transform: uppercase;
  grid-column: 1/-1;
}

.FeatureBloc_title__iXNhm .FeatureBloc_capsule__IhV2m {
  padding: 0 0.5em;
  margin-left: 1em;
  border-radius: 99vmax;
  text-transform: none;
  border: 1px solid var(--color-neutral);
}

.large-screen_priceList__Q6IgJ {
  display: grid;
  grid-template-columns: 280px repeat(var(--custom-plans-count), 280px);
  gap: 16px;
  width: auto;
  overflow-x: auto;
  scroll-snap-points-y: repeat(296px);
  scroll-snap-type: x mandatory;
  margin: 1em 0;
}

.large-screen_priceList__Q6IgJ hr {
  width: 100%;
  height: 1px;
  border: none;
  background-color: var(--color-neutral-light);
}

.large-screen_fullWidth__Hx1d5 {
  grid-column: 1/-1;
}

.large-screen_priceList__Q6IgJ em {
  color: var(--color-neutral);
  font-style: normal;
}

.large-screen_priceList__Q6IgJ big {
  font-size: var(--font-size-L);
}

.large-screen_notesExplanation__Z9So_ > li::marker {
  display: none;
}

.large-screen_header__a_3Z6 {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.large-screen_header__a_3Z6 > div {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1em;
  margin-top: 1em;
  margin-bottom: 2em;
}

@media (max-width: 480px) {
  .large-screen_priceList__Q6IgJ,
  .large-screen_notesExplanation__Z9So_ {
    display: none;
  }
}


.card_swipeableCard__P7qcd {
  position: relative;
  width: min(640px, calc(100vw - 8px));
  height: 240px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  gap: 0;
  flex: 0 0 auto;
  scroll-snap-stop: always;
  scroll-snap-align: start;
  border: 1px solid var(--color-neutral);
}

.card_swipeableCard__P7qcd > div.card_content__sUYyk {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  padding-right: 16px;
  z-index: 1;
  padding: 1em;
}

.card_swipeableCard__P7qcd > div.card_content__sUYyk > * {
  flex: 0 0 auto;
}

.card_swipeableCard__P7qcd > div.card_content__sUYyk > small {
  flex: 1 1 auto;
  color: #0008;
  font-weight: normal;
}

.card_swipeableCard__P7qcd > div.card_content__sUYyk > p {
  margin: 0;
}

.card_swipeableCard__P7qcd > div.card_picture__WvBqP {
  z-index: 0;
  width: 240px;
  flex: 0 0 auto;
  position: relative;
}

.card_swipeableCard__P7qcd > div.card_picture__WvBqP > * {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: top center;
     object-position: top center;
}

@media (max-width: 600px) {
  .card_swipeableCard__P7qcd > div.card_picture__WvBqP {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .card_swipeableCard__P7qcd > div.card_content__sUYyk {
    padding: 1em;
    background-color: color-mix(in srgb, var(--color-primary) 66%, transparent);
    color: #fffe;
  }

  .card_swipeableCard__P7qcd > div.card_content__sUYyk > small {
    color: #fffc;
  }
}

.member_member__8vHER {
  max-width: 360px;
}

.member_content__1IFh7 {
  background-color: var(--color-neutral-light);
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  gap: 1em;
  padding: 1em;
}

.member_picture__so755 {
  width: 100;
  aspect-ratio: 16/9;
  -o-object-fit: cover;
     object-fit: cover;
}

.member_member__8vHER > .member_content__1IFh7 > button {
  width: 8em;
  height: 2em;
  display: grid;
  place-items: center;
  background-color: transparent;
  border: 1px solid #0003;
}

.member_member__8vHER > .member_content__1IFh7 > button:hover,
.member_member__8vHER > .member_content__1IFh7 > button:active {
  background-color: var(--color-primary);
}

.multiple-member_multipleMember__Rsd9q {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  gap: 2em;
  margin: 1em 0;
}

h3.section-title_sectionTitle__4huc4 {
  font-size: var(--font-size-section);
}

.lab-list_fullWidth__sFxv3 {
  grid-column: 1/-1;
}

h3.lab-list_fullWidth__sFxv3 {
  font-size: var(--font-size-section);
}

hr.lab-list_fullWidth__sFxv3 {
  width: 100%;
  height: 1px;
  background-color: var(--color-neutral);
  margin: 64px 0;
}

.lab-list_creditType__e2ft4 {
  color: var(--color-neutral);
  font-size: var(--font-size-S);
}

h3.lab-list_bloc__LdlYq {
  color: var(--color-neutral);
  text-transform: uppercase;
  font-family: var(--font-sans-serif);
}

.general-list_type__etwiA {
  display: inline-block;
  color: var(--color-neutral);
  padding-left: 1em;
}

.price-list-2_priceList2__VJgZv {
  display: grid;
  grid-template-columns: 280px repeat(var(--custom-plans-count), 280px);
  gap: 16px;
  width: auto;
  overflow-x: auto;
  scroll-snap-points-y: repeat(296px);
  scroll-snap-type: x mandatory;
  margin: 1em 0;

  --font-size-section: calc(1.4 * var(--font-size-M));
}

.price-list-2_priceList2__VJgZv .price-list-2_fullWidth__IHCmL {
  grid-column: 1/-1;
}

.price-list-2_priceList2__VJgZv * {
  line-height: 1 !important;
}

.repository-card_repositoryCard__A_Uql {
  padding: 20px;
  border: 1px solid var(--color-neutral);
}

.repository-card_repositoryCard__A_Uql > h2 {
  font-size: var(--font-size-XL);
}

.repository-card_repositoryCard__A_Uql > header {
  font-size: var(--font-size-S);
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1em;
}

.repository-card_repositoryCard__A_Uql p {
  line-height: 1.55;
}

.repository-card_authorLabel__kqosL {
  color: var(--color-neutral);
}

.repository-card_authorName__dKEIW {
  border-radius: 99vmax;
  border: 1px solid var(--color-neutral);
  padding: 0 1em;
  line-height: 2;
}

.repository-card_image__L7Men {
  width: 100%;
  aspect-ratio: 3/1;
  margin: 1em 0;
}

a.repository-card_button__nRki3 {
  display: inline-block;
  width: auto;
  height: 3em;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
  padding: 0 1em;
  border: 1px solid var(--color-neutral);
  margin-top: 1em;
}

.repository-card_buttons__QN1Py {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1em;
}

.repositories_repositories__VtG89 {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 32px;
  margin: 1em 0;
}

@media (min-width: 640px) {
  .repositories_repositories__VtG89 {
    padding: 0 24px;
    grid-template-columns: repeat(auto-fill, minmax(600px, 1fr));
  }
}

@media (min-width: 1600px) {
  .repositories_repositories__VtG89 {
    padding: 0 24px;
    grid-template-columns: repeat(auto-fill, minmax(800px, 1fr));
  }
}

.card_card__l0ADa {
  width: 280px;
  height: 320px;
  display: grid;
  grid-template-rows: 1fr auto auto;
}

.card_name__AAGra {
  font-family: var(--font-serif);
  font-size: var(--font-size-M-plus);
  line-height: 1;
  padding: 0.4em 0.5em;
  background-color: var(--color-neutral-light);
}

.card_links__4kyF8 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.card_links__4kyF8 > a {
  font-size: var(--font-size-M);
  display: inline-block;
  cursor: pointer;
  font-size: 1rem;
  border: solid 1px var(--color-neutral);
  padding: 6px 12px;
}

.special-contributors_specialContributors__kzyWv {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 32px;
  margin: 1em 0;
}

.button_button__MPAE6 {
  all: unset;
  cursor: pointer;
}

.milestones_milestones__O62mI {
  background-color: var(--color-primary);
  color: #fffe;
  display: grid;
  grid-template-columns: var(--padM) min(80vw, 480px) 1fr;
  grid-template-rows: 10vh auto auto 1fr auto 10vh;
  grid-template-areas:
    '... ... img'
    'rlr ttl img'
    '... cnt img'
    '... dsc img'
    '... ftr img'
    '... ... img'
    '... ... ...';
  gap: 16px;
  padding: 0;
  min-height: 50vw;
}

@media (max-width: 640px) {
  .milestones_milestones__O62mI {
    grid-template-columns: var(--padM) 1fr var(--padM);
    grid-template-rows: auto auto auto auto 1fr;
    grid-template-areas:
      'rlr ttl'
      '... cnt'
      '... img'
      '... ftr'
      '... dsc';
    padding: 64px 0;
  }
}

.milestones_milestones__O62mI > .milestones_image__DFtRy {
  min-height: 200px;
}

.milestones_milestones__O62mI > .milestones_image__DFtRy > img {
  -o-object-fit: contain;
     object-fit: contain;
}

.milestones_milestones__O62mI > hr.milestones_ruler__MSWhv {
  content: '';
  position: relative;
  display: block;
  grid-area: rlr;
  font-size: var(--font-size-XL);
  height: 100%;
  transform: translateY(0.5em);
  border: none;
}

.milestones_milestones__O62mI > hr.milestones_ruler__MSWhv:after {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background: currentColor;
  border: none;
}

.milestones_milestones__O62mI > h1 {
  grid-area: ttl;
  font-size: var(--font-size-XL);
  margin: 0;
}

.milestones_milestones__O62mI > p {
  grid-area: dsc;
  line-height: 1.5;
}

.milestones_milestones__O62mI > footer {
  grid-area: ftr;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1em;
}

.milestones_milestones__O62mI > footer > div {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5em;
}

.milestones_milestones__O62mI > .milestones_counts__xR3dH {
  grid-area: cnt;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0;
}

.milestones_milestones__O62mI > .milestones_counts__xR3dH > div {
  color: var(--color-primary-light);
  border: 1px solid currentColor;
  height: 2em;
  display: grid;
  place-items: center;
  padding: 0 1em;
  margin-right: -1px;
}

.milestones_milestones__O62mI > .milestones_image__DFtRy {
  grid-area: img;
}

.milestones_dash__1Pvww {
  content: '';
  display: block;
  width: 1em;
  height: 0.25em;
  border-radius: 99vmax;
  background-color: var(--color-primary-light);
}

.milestones_dash__1Pvww.milestones_selected__pWch1 {
  background-color: #fffe;
}

.SanityContentVerticalSpace_sanityContentVerticalSpace__R0vKn {
  content: '';
  margin: 0;
  display: block;
}

.SanityContentVerticalSpace_small__juIzZ {
  height: 12px;
}

.SanityContentVerticalSpace_medium__y7DZw {
  height: 36px;
}

.SanityContentVerticalSpace_large__RQNDo {
  height: 108px;
}

.VerticalSpace_verticalSpace__5byY8 {
}

.SanityContentPreview_sanityContentPreview__LDpQQ {
  position: relative;
  padding: 10vh 0;
}

.SanityContentPreview_sanityContentPreview__LDpQQ.SanityContentPreview_dark__RMwgF {
  background-color: var(--color-primary);
  color: #fffe;
}

.SanityContentPreview_sanityContentPreview__LDpQQ.SanityContentPreview_light__3HYYg {
  background-color: transparent;
  color: var(--color-primary);
}

.SanityContentPreview_background__agHsK {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.SanityContentPreview_content__aPPOB {
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: flex-start;
  align-items: stretch;
}

.SanityContentPreview_vignette__1qgw0 {
  flex: 0 0 auto;
  -o-object-fit: cover;
     object-fit: cover;
  width: 40%;
  height: 240px;
}

.SanityContentPreview_text__ivnWe {
  flex: 1 1 auto;
  z-index: 9;
}

.SanityContentPreview_sanityContentPreview__LDpQQ button.SanityContentPreview_button__0OLy0 {
  all: unset;
  cursor: pointer;
  padding: 1em 2em;
  border: 1px solid currentColor;
  transition: all 0.4s;
  margin-top: 1em;
}

.SanityContentPreview_sanityContentPreview__LDpQQ.SanityContentPreview_dark__RMwgF button.SanityContentPreview_button__0OLy0:hover {
  color: var(--color-primary);
  background-color: #fff;
}

.SanityContentPreview_sanityContentPreview__LDpQQ.SanityContentPreview_light__3HYYg button.SanityContentPreview_button__0OLy0:hover {
  color: #fff;
  background-color: var(--color-primary);
}

@media (max-width: 640px) {
  .SanityContentPreview_content__aPPOB {
    flex-direction: column;
  }

  .SanityContentPreview_vignette__1qgw0 {
    order: 2;
    width: calc(100vw - 16px);
    max-width: none;
    max-height: 240px;
  }

  .SanityContentPreview_text__ivnWe {
    order: 1;
  }
}

.sanity-content-image_sanityContentImage__LxBjf {
  width: 100%;
  height: auto;
  aspect-ratio: var(--custom-aspect-ratio);
  position: relative;
}

.sanity-content-image_sanityContentImage__LxBjf > * {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.sanity-content-video_sanityContentVideo__G4MTp nav {
  display: grid;
  grid-template-columns: repeat(var(--custom-columns), 1fr);
  place-items: center;
  gap: 1.5em;
  font-size: var(--font-size-S);
  margin: 1em 0;
}

.sanity-content-video_sanityContentVideo__G4MTp > nav > button.sanity-content-video_step__mFfmn {
  font-size: var(--font-size-S);
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}

div.sanity-content-video_currentStep__LRud2 {
  content: '';
  height: 4px;
  width: 32px;
  border-radius: 99vmax;
  background-color: currentColor;
}

.sanity-content-video_sanityContentVideo__G4MTp > article {
  margin-bottom: 2em;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 3em;
}

.sanity-content-video_sanityContentVideo__G4MTp > article > div:first-child {
  white-space: nowrap;
}

@media (max-width: 480px) {
  .sanity-content-video_sanityContentVideo__G4MTp > article {
    flex-direction: column;
    gap: 2em;
  }
}

a.single-button_singleButton__VwkKU {
  position: relative;
  overflow: hidden;
  background-color: var(--color-primary);
  border-radius: 16px;
  width: 588px;
  max-width: calc(100vw - 16px);
  height: 250px;
  padding: 32px;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  font-family: var(--font-sans-serif);
  font-size: var(--font-size-M);
  color: #fffe;
}

.single-button_background__29N_2 {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

a.single-button_singleButton__VwkKU h1 {
  font-family: var(--font-serif);
  font-size: var(--font-size-L);
  margin: 0;
}

a.single-button_singleButton__VwkKU > div,
a.single-button_singleButton__VwkKU > svg {
  z-index: 2;
}

.sanity-content-multiple-button_sanityContentMultipleButton__LfBjM {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 24px;
  padding: 24px 0;
}

button.NextPanel_nextPanel__9YzS1 {
  all: unset;
  width: 100%;
  color: #fff;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  gap: 1em;
  height: 120px;
  margin: 0;
  cursor: pointer;
  opacity: 0.8;
  transition: opacity 0.2s;
}

button.NextPanel_nextPanel__9YzS1 > div {
  text-align: center;
}

button.NextPanel_nextPanel__9YzS1:active,
button.NextPanel_nextPanel__9YzS1:hover {
  opacity: 1;
}

button.NextPanel_nextPanel__9YzS1 > div.NextPanel_ruler__16_hM {
  content: '';
  margin: 0;
  height: 100%;
  width: 2px;
  background-color: #fff;
}

@media (max-height: 480px) {
  button.NextPanel_nextPanel__9YzS1 {
    height: 48px;
    gap: 0.125em;
  }
}

.Hero_hero__hCo2d {
  position: relative;
  margin: 0;
  width: 100vw;
  height: max(100vh, 600px);
  color: #fffe;
  background-color: var(--color-primary);

  --color-bg: var(--color-primary);
  --gradient-angle: 150deg;
}

.Hero_hero__hCo2d .Hero_background__hT0Yh {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.Hero_hero__hCo2d .Hero_background__hT0Yh > * {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.Hero_hero__hCo2d .Hero_background__hT0Yh > video {
  transition: opacity 1.2s;
}

.Hero_hero__hCo2d .Hero_background__hT0Yh > img {
  transition: opacity 0.3s;
}

.Hero_hero__hCo2d .Hero_background__hT0Yh > .Hero_show__SqYTx {
  opacity: 1;
}

.Hero_hero__hCo2d .Hero_background__hT0Yh > .Hero_hide__EzsMt {
  opacity: 0;
}

.Hero_hero__hCo2d .Hero_text__N9i7W {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: linear-gradient(
    var(--gradient-angle),
    var(--color-bg) 29.85%,
    rgba(240, 240, 240, 0) 61.87%
  );
  z-index: 1;
  font-size: 4vmin;
  line-height: 1;
  padding: 0 7vw;
  text-wrap: balance;
  max-width: auto;
}

.Hero_hero__hCo2d .Hero_text__N9i7W h1 {
  margin: 0;
  margin-bottom: 0.25em;
  font-size: 10vmin;
  padding: 0;
}

.Hero_hero__hCo2d .Hero_text__N9i7W h1::after {
  display: none;
}

.Hero_hero__hCo2d .Hero_text__N9i7W div.Hero_content__7WfKY {
  line-height: 1.2;
  max-width: auto;
}

.Hero_hero__hCo2d > footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 9;
}

.Hero_comingSoon__AJYkn {
  z-index: 8;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
}

button.CategoryButton_categoryButton__7I89B {
  all: unset;
  white-space: nowrap;
  font-size: var(--font-size-M);
  text-transform: uppercase;
  border-radius: 99vmax;
  line-height: 2;
  padding: 0 1em;
  border: 1px solid currentColor;
  cursor: pointer;
  background: #fff;
  color: var(--color-primary);
  transition: all 0.5s;
}

button.CategoryButton_categoryButton__7I89B.CategoryButton_selected__vsRam {
  color: #fffe;
  background-color: var(--color-primary);
}

button.CategoryButton_categoryButton__7I89B[disabled] {
  font-size: calc(0.5 * var(--font-size-M));
  pointer-events: none;
  cursor: default;
  filter: grayscale(100%);
  opacity: 0.6;
}

.Card_card__xMmmo {
  max-width: 1000px;
  margin: 8px;
  margin-bottom: 64px;
}

.Card_card__xMmmo.Card_small__t51jy {
  max-width: 480px;
}

.Card_card__xMmmo.Card_small__t51jy .Card_image__2oQ7L {
  width: 100%;
}

.Card_card__xMmmo.Card_small__t51jy .Card_text__POWQG {
  order: 2;
}

.Card_card__xMmmo.Card_small__t51jy .Card_content__7Efso {
  flex-direction: column;
  height: auto;
}

.Card_card__xMmmo > h1 {
  margin: 0;
}

.Card_subtitle__kIupr {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 80%;
  color: var(--color-neutral);
  gap: 2em;
  margin-bottom: 2em;
  margin-top: 0.5em;
}

.Card_content__7Efso {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: strech;
  gap: 16px;
  height: 240px;
}

.Card_text__POWQG {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1em;
}

.Card_text__POWQG > div > span {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
}

.Card_text__POWQG > button.Card_button__sTbDO {
  all: unset;
  cursor: pointer;
  border: 1px solid var(--color-neutral);
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
  padding: 0 2em;
  height: 3em;
  min-height: 3em;
  margin-top: 1em;
  transition: all 0.3s;
}

.Card_text__POWQG > button.Card_button__sTbDO:hover {
  color: #fffe;
  background-color: var(--color-primary);
}

.Card_image__2oQ7L {
  width: 50%;
  order: 1;
}

@media (max-width: 480px) {
  .Card_card__xMmmo {
    margin: 64px 0;
  }

  .Card_content__7Efso {
    flex-direction: column;
    height: auto;
  }

  .Card_text__POWQG {
    order: 2;
  }

  .Card_image__2oQ7L {
    width: 100%;
  }
}

.SectionNews_news__UyVkq {
  margin-top: 64px;
}

.SectionNews_news__UyVkq > header {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2em;
  color: var(--color-neutral);
  margin-bottom: 128px;
}

.SectionNews_news__UyVkq > header > div.SectionNews_label__zChZE {
  line-height: 2;
  white-space: nowrap;
  flex: 0 0 auto;
}

.SectionNews_news__UyVkq > header > div.SectionNews_buttons__7YMDF {
  flex: 1 1 auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1em;
}

h1.SectionNews_separator__WdQI2 {
  font-size: calc(1.4 * var(--font-size-XL));
  color: var(--color-neutral);
  margin: 0;
  margin-top: 2em;
}

hr.SectionNews_separator__WdQI2 {
  margin-top: 1em;
  margin-bottom: 3em;
}

.SectionNews_copyright__N9Gh0 {
  color: var(--color-neutral);
  font-size: var(--font-size-S);
}

div.SectionNews_epfl__0a6aH {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
}

/* Prevent the dorpdown to be hidden by sticky headers. */
div > div.ant-select-dropdown {
  z-index: 9999999;
}

.NewsPage_blueBackground__tvIVX {
  width: 100%;
  overflow: visible;
  height: 33vh;
  z-index: 0;
}

.NewsPage_blueBackground__tvIVX > div {
  width: 100vw;
  height: 100vh;
  background-color: var(--color-primary);
  z-index: 0;
}

.NewsPage_newsPage__SsL76 {
  z-index: 1;
}

header.NewsPage_header__I0Lib {
  grid-column: 1/-1;
  color: #fffe;
}

header.NewsPage_header__I0Lib h1 {
  font-size: calc(2 * var(--font-size-XL));
  margin: 0;
  margin-bottom: 0.5em;
}

header.NewsPage_header__I0Lib h2 {
  font-family: var(--font-sans-serif);
}

.NewsPage_notes__nDjtS {
  color: #3bf;
  font-size: var(--font-size-M);
  margin-bottom: 3em;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
}

