.priceList {
  display: grid;
  grid-template-columns: 280px repeat(var(--custom-plans-count), 280px);
  gap: 16px;
  width: auto;
  overflow-x: auto;
  scroll-snap-points-y: repeat(296px);
  scroll-snap-type: x mandatory;
  margin: 1em 0;
}

.priceList hr {
  width: 100%;
  height: 1px;
  border: none;
  background-color: var(--color-neutral-light);
}

.fullWidth {
  grid-column: 1/-1;
}

.priceList em {
  color: var(--color-neutral);
  font-style: normal;
}

.priceList big {
  font-size: var(--font-size-L);
}

.notesExplanation > li::marker {
  display: none;
}

.header {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.header > div {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1em;
  margin-top: 1em;
  margin-bottom: 2em;
}

@media (max-width: 480px) {
  .priceList,
  .notesExplanation {
    display: none;
  }
}
