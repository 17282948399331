.swipeableCardsList {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  margin: var(--gap-L) 0;
  gap: var(--gap-S);
}

.scroll {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: var(--custom-gap, 48px);
  flex-wrap: nowrap;
  scroll-behavior: smooth;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  padding-bottom: 24px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.swipeableCardsList > footer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
  padding: 0;
  padding-right: 16px;
}

.swipeableCardsList > footer.onlyOnSmallScreen {
  display: none;
}

@media (max-width: 480px) {
  .swipeableCardsList > footer.onlyOnSmallScreen {
    display: flex;
  }

  .swipeableCardsList > footer {
    flex-direction: column;
    gap: 2em;
  }

  .swipeableCardsList > footer > div {
    order: 2;
  }
}

.swipeableCardsList nav {
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  gap: 8px;
  order: 1;
}

.swipeableCardsList nav > button.page {
  content: '';
  width: 24px;
  height: 6px;
  border-radius: 99vmax;
  background-color: #0004;
}

.swipeableCardsList nav > button.page.selected {
  background-color: var(--color-primary);
}

.swipeableCardsList nav > button.arrow {
  width: 3em;
  display: grid;
  place-items: center;
}
