.card {
  max-width: 1000px;
  margin: 8px;
  margin-bottom: 64px;
}

.card.small {
  max-width: 480px;
}

.card.small .image {
  width: 100%;
}

.card.small .text {
  order: 2;
}

.card.small .content {
  flex-direction: column;
  height: auto;
}

.card > h1 {
  margin: 0;
}

.subtitle {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 80%;
  color: var(--color-neutral);
  gap: 2em;
  margin-bottom: 2em;
  margin-top: 0.5em;
}

.content {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: strech;
  gap: 16px;
  height: 240px;
}

.text {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1em;
}

.text > div > span {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
}

.text > button.button {
  all: unset;
  cursor: pointer;
  border: 1px solid var(--color-neutral);
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
  padding: 0 2em;
  height: 3em;
  min-height: 3em;
  margin-top: 1em;
  transition: all 0.3s;
}

.text > button.button:hover {
  color: #fffe;
  background-color: var(--color-primary);
}

.image {
  width: 50%;
  order: 1;
}

@media (max-width: 480px) {
  .card {
    margin: 64px 0;
  }

  .content {
    flex-direction: column;
    height: auto;
  }

  .text {
    order: 2;
  }

  .image {
    width: 100%;
  }
}
