.menuContainer {
  --size-button: 160px;
  --size-header: var(--size-button);

  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: var(--font-size-M);
  color: #fffe;
  font-size: var(--font-size-M);
  z-index: 999;
  background-color: transparent;
  transition: all 0.6s;
}

.menuContainer.stuck {
  background-color: color-mix(in srgb, var(--color-primary) 90%, transparent);
  backdrop-filter: blur(5px);
}

.menuContainer .logo {
  flex: 0 0 auto;
  max-width: 8em;
}

.menuContainer .logo h2 {
  text-align: right;
  line-height: 0.8;
}

.menuContainer > div.items {
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  gap: 0;
  justify-content: flex-end;
  align-items: flex-start;
  line-height: 2;
}

.menuContainer div.items a {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  margin: 0;
  padding: 0 1em;
}

.menuContainer div.items a::after {
  content: '';
  display: block;
  height: 4px;
  width: 48px;
  background-color: #fffe;
  border-radius: 999vmax;
  opacity: 0;
  transition: all 0.5s;
}

.menuContainer div.items a:hover::after {
  opacity: 0.5;
}

.menuContainer div.items a.selected::after {
  opacity: 1;
}

.menuContainer div.items a.loginButton::after {
  display: none;
}

.menuContainer div.items a.loginButton {
  border: 1px solid currentColor;
  border-radius: 4px;
  color: var(--color-primary);
  background-color: #fffe;
  transition: all 0.6s;
}

.menuContainer div.items a.loginButton:hover {
  background-color: var(--color-primary);
  color: #fffe;
}

.menuContainer > div.hamburger {
  display: none;
}

.menuContainer > div.hamburger > button {
  font-size: var(--font-size-L);
}

@media (max-width: 950px) {
  .menuContainer > div.items {
    display: none;
  }

  .menuContainer div.hamburger {
    display: block;
  }
}
