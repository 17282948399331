.progressiveImage {
  position: relative;
  background: var(--custom-background, transparent);
  aspect-ratio: var(--custom-aspect-ratio);
}

.progressiveImage > .image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.3s;
  opacity: 0;
}

.progressiveImage > .image.show {
  opacity: 1;
}
