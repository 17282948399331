.ourFoundations {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 20vw;
  grid-auto-rows: auto;
  grid-template-areas:
    'S P'
    'L P';
  gap: 0;
  place-items: stretch;
  overflow-x: hidden;
  margin: 1em 0;
}

.section {
  grid-area: S;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  background-color: var(--color-neutral-light);
  padding: 2.5em;
}

.picture {
  grid-area: P;
  position: relative;
  width: 100%;
  height: 100%;
}

.picture > * {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.section h2 {
  width: 100%;
  font-family: var(--font-serif);
  font-size: var(--font-size-L);
  line-height: 1.1;
  margin: 0;
  margin-bottom: 2em;
  max-width: none;
}

.section h3 {
  font-size: var(--font-size-M);
  text-transform: uppercase;
  margin-bottom: 10px;
  font-family: var(--font-sans-serif);
}

.section p {
  font-size: var(--font-size-M);
  margin: 0;
  padding: 0;
}

.ourFoundations > footer {
  grid-area: L;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  /* Important: this gap plus the outline in the buttons make the border to collapse properly. */
  gap: 1px;
  border: 1px solid var(--color-neutral);
}

.ourFoundations > footer > .link {
  width: 100%;
  border: none;
  outline: 1px solid var(--color-neutral);
}

.ourFoundations > footer > .link > div {
  width: 100%;
  max-width: none;
  margin: 0;
  border: none;
  outline: none;
}

@media (max-width: 1000px) {
  .ourFoundations {
    grid-template-areas:
      'S S'
      'L P';
  }

  .ourFoundations > footer {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }

  .section {
    padding: 2em;
  }
}

@media (max-width: 480px) {
  .ourFoundations {
    grid-template-areas:
      'S'
      'P'
      'L';
    grid-template-columns: 1fr;
    grid-template-rows: auto 200px auto;
  }

  .section {
    padding: 1em;
  }

  .section h2 {
    font-size: 7vw;
  }

  .section > div > img {
    display: none;
  }

  .section > div {
    z-index: 1;
  }

  .picture {
    width: 100%;
  }

  .ourFoundations > footer > .link {
    position: relative;
    justify-content: stretch;
    height: 5rem;
  }

  .ourFoundations > footer > .link > div {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}
