.member {
  max-width: 360px;
}

.content {
  background-color: var(--color-neutral-light);
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  gap: 1em;
  padding: 1em;
}

.picture {
  width: 100;
  aspect-ratio: 16/9;
  object-fit: cover;
}

.member > .content > button {
  width: 8em;
  height: 2em;
  display: grid;
  place-items: center;
  background-color: transparent;
  border: 1px solid #0003;
}

.member > .content > button:hover,
.member > .content > button:active {
  background-color: var(--color-primary);
}
