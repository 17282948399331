.repositories {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 32px;
  margin: 1em 0;
}

@media (min-width: 640px) {
  .repositories {
    padding: 0 24px;
    grid-template-columns: repeat(auto-fill, minmax(600px, 1fr));
  }
}

@media (min-width: 1600px) {
  .repositories {
    padding: 0 24px;
    grid-template-columns: repeat(auto-fill, minmax(800px, 1fr));
  }
}
