button.missionStatement {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  gap: 0;
  max-width: 1200px;
  cursor: pointer;
  border-radius: 16px;
  box-shadow: 0 16px 32px #0005;
  overflow: hidden;
  margin: 5rem 0;
  border-radius: 16px;
  box-shadow: 0 16px 32px #0005;
  background-color: var(--color-neutral-light);
  overflow: hidden;
  margin: 5rem 0;
}

.missionStatement > div.text {
  display: flex;
  flex-direction: column;
  gap: 0;
  justify-content: center;
  align-items: stretch;
  padding: 24px;
}

.missionStatement > div.text > * {
  flex: 0 0 auto;
}

.missionStatement > div.text > h2 {
  font-family: var(--font-serif);
  font-size: var(--font-size-XL);
  line-height: 1.2;
  margin-left: 2em;
}

.missionStatement > div.background {
  position: relative;
  background-color: var(--color-primary);
  display: grid;
  place-items: center;
  color: #fff;
  font-size: 48px;
  min-width: 240px;
  min-height: 240px;
}

.missionStatement > div.background .image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

.missionStatement > div.background .icon {
  z-index: 2;
}

@media (max-width: 800px) {
  button.missionStatement {
    flex-direction: column;
  }
}
