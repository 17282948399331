div.video {
  position: relative;
  margin: 0;
  padding: 0;
}

div.video > video {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.pointer {
  cursor: pointer;
}
