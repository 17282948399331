.contactUs {
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  min-height: 100px;
}
