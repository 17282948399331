.milestones {
  background-color: var(--color-primary);
  color: #fffe;
  display: grid;
  grid-template-columns: var(--padM) min(80vw, 480px) 1fr;
  grid-template-rows: 10vh auto auto 1fr auto 10vh;
  grid-template-areas:
    '... ... img'
    'rlr ttl img'
    '... cnt img'
    '... dsc img'
    '... ftr img'
    '... ... img'
    '... ... ...';
  gap: 16px;
  padding: 0;
  min-height: 50vw;
}

@media (max-width: 640px) {
  .milestones {
    grid-template-columns: var(--padM) 1fr var(--padM);
    grid-template-rows: auto auto auto auto 1fr;
    grid-template-areas:
      'rlr ttl'
      '... cnt'
      '... img'
      '... ftr'
      '... dsc';
    padding: 64px 0;
  }
}

.milestones > .image {
  min-height: 200px;
}

.milestones > .image > img {
  object-fit: contain;
}

.milestones > hr.ruler {
  content: '';
  position: relative;
  display: block;
  grid-area: rlr;
  font-size: var(--font-size-XL);
  height: 100%;
  transform: translateY(0.5em);
  border: none;
}

.milestones > hr.ruler:after {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background: currentColor;
  border: none;
}

.milestones > h1 {
  grid-area: ttl;
  font-size: var(--font-size-XL);
  margin: 0;
}

.milestones > p {
  grid-area: dsc;
  line-height: 1.5;
}

.milestones > footer {
  grid-area: ftr;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1em;
}

.milestones > footer > div {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5em;
}

.milestones > .counts {
  grid-area: cnt;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0;
}

.milestones > .counts > div {
  color: var(--color-primary-light);
  border: 1px solid currentColor;
  height: 2em;
  display: grid;
  place-items: center;
  padding: 0 1em;
  margin-right: -1px;
}

.milestones > .image {
  grid-area: img;
}

.dash {
  content: '';
  display: block;
  width: 1em;
  height: 0.25em;
  border-radius: 99vmax;
  background-color: var(--color-primary-light);
}

.dash.selected {
  background-color: #fffe;
}
