button.nextPanel {
  all: unset;
  width: 100%;
  color: #fff;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  gap: 1em;
  height: 120px;
  margin: 0;
  cursor: pointer;
  opacity: 0.8;
  transition: opacity 0.2s;
}

button.nextPanel > div {
  text-align: center;
}

button.nextPanel:active,
button.nextPanel:hover {
  opacity: 1;
}

button.nextPanel > div.ruler {
  content: '';
  margin: 0;
  height: 100%;
  width: 2px;
  background-color: #fff;
}

@media (max-height: 480px) {
  button.nextPanel {
    height: 48px;
    gap: 0.125em;
  }
}
